import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/mdxPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`These are the available feeds:`}</p>
    <ul>
      <li parentName="ul">{``}<abbr parentName="li" {...{
          "title": "Really Simple Syndication"
        }}>{`RSS`}</abbr>{`: `}<a parentName="li" {...{
          "href": "/feed.xml"
        }}>{`feed.xml`}</a></li>
      <li parentName="ul">{``}<abbr parentName="li" {...{
          "title": "JavaScript Object Notation"
        }}>{`JSON`}</abbr>{`: `}<a parentName="li" {...{
          "href": "/feed.json"
        }}>{`feed.json`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      